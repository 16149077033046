















import { Component, Mixins } from 'vue-property-decorator';

import { ListingFilter, ProfileFilter } from 'client-website-ts-library/filters';
import {
  ListingStatus,
  ListingCategory,
  Listing,
  Profile,
} from 'client-website-ts-library/types';

import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import ProfileCardTv from '@/components/ProfileCardTv.vue';
import ListingCardTv from '@/components/ListingCardTv.vue';

@Component({
  components: {
    ProfileCardTv,
    ListingCardTv,
  },
})
export default class TVDisplayPortrait extends Mixins(View) {
  private dwellTime = 10 * 1000;

  private maxListings = 5;

  private listingFilterSales = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    Categories: [
      ListingCategory.ResidentialSale,
      ListingCategory.ResidentialLand,
    ],
    PageSize: this.maxListings,
  });

  private listingFilterRentals= new ListingFilter({
    Statuses: [
      ListingStatus.Current,
    ],
    Categories: [
      ListingCategory.ResidentialRental,
    ],
    PageSize: this.maxListings,
  });

  private listings: Listing[] = [];

  private listing: Listing | null = null;

  private profiles: Profile[] = [];

  private profile: Profile | null = null;

  private nextTimeout?: number;

  private currentIdx = 0;

  private profileIdx = 0;

  private listingIdx = 0;

  queueNextListing() {
    clearTimeout(this.nextTimeout);

    this.nextTimeout = setTimeout(() => {
      this.listingIdx += 1;

      if (this.listingIdx >= this.listings.length && this.listings.length > 0) {
        this.updateListings().then(() => {
          // eslint-disable-next-line prefer-destructuring
          this.listingIdx = 0;
          this.listing = null;
        });
      } else {
        this.listing = null;
      }
    }, this.dwellTime);
  }

  queueNextProfile() {
    clearTimeout(this.nextTimeout);

    this.nextTimeout = setTimeout(() => {
      this.profileIdx += 1;
      if (this.profileIdx >= this.profiles.length && this.profiles.length > 0) {
        this.profileIdx = 0;
        this.profile = null;
      } else {
        this.profile = null;
      }
    }, this.dwellTime);
  }

  handleAfterLeave() {
    if (this.currentIdx % 2 === 0) {
      this.listing = this.listings[this.listingIdx];
      this.queueNextListing();
    } else {
      this.profile = this.profiles[this.profileIdx];
      this.queueNextProfile();
    }

    this.currentIdx += 1;
  }

  updateListings() {
    return new Promise((resolve) => {
      API.Listings.Search(this.listingFilterSales).then((listingsSales) => {
        this.listings = listingsSales.Items;
      }).then(() => {
        API.Listings.Search(this.listingFilterRentals).then((listingsRental) => {
          this.listings = this.listings.concat(listingsRental.Items);
        });
      }).then(() => {
        API.Profiles.Search(new ProfileFilter(), true).then((profiles) => {
          this.profiles = profiles;
          this.handleAfterLeave();
          resolve();
        });
      });
    });
  }

  mounted() {
    this.updateListings();
  }

  get formattedAddressLine1(): string {
    const parts = [];

    if (this.listing!.Address.UnitNumber) {
      parts.push(`${this.listing!.Address.UnitNumber}/${this.listing!.Address.StreetNumber}`);
    } else {
      parts.push(this.listing!.Address.StreetNumber);
    }

    parts.push(this.listing!.Address.Address);

    return parts.join(' ');
  }

  private get dayOfMonthSuffix(): string {
    const dayOfMoth = this.listing!.Auction.Date.DayOfMonth;

    if (dayOfMoth > 3 && dayOfMoth < 21) return `${dayOfMoth}th`;
    switch (dayOfMoth % 10) {
      case 1: return `${dayOfMoth}st`;
      case 2: return `${dayOfMoth}nd`;
      case 3: return `${dayOfMoth}rd`;
      default: return `${dayOfMoth}th`;
    }
  }
}
