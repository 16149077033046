


































































































import { Component, Mixins, Prop } from 'vue-property-decorator';

import { Listing } from 'client-website-ts-library/types';

import { View } from 'client-website-ts-library/plugins';
import { Config } from 'client-website-ts-library/services';

@Component
export default class ListingCardTv extends Mixins(View) {
  @Prop()
  private readonly listing!: Listing;

  private isFullScreen = false;

  get qrCodeLink() {
    if (this.listing === null) return null;

    return `${Config.API.Base}/api/links/qrcode/listings/${this.listing.Id}?clientWebsiteId=${Config.Website.Id}`;
  }

  get methodOfSale() {
    if (this.listing === null) return 'For Sale';

    if (this.listing.MethodOfSale.indexOf('For') !== -1) return this.listing.MethodOfSale;

    return `For ${this.listing.MethodOfSale}`;
  }

  fullScreen() {
    this.isFullScreen = !this.isFullScreen;

    if (this.isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  get formattedAddressLine1(): string {
    const parts = [];

    if (this.listing!.Address.UnitNumber) {
      parts.push(`${this.listing!.Address.UnitNumber}/${this.listing!.Address.StreetNumber}`);
    } else {
      parts.push(this.listing!.Address.StreetNumber);
    }

    parts.push(this.listing!.Address.Address);

    return parts.join(' ');
  }

  private get dayOfMonthSuffix(): string {
    const dayOfMoth = this.listing!.Auction.Date.DayOfMonth;

    if (dayOfMoth > 3 && dayOfMoth < 21) return `${dayOfMoth}th`;
    switch (dayOfMoth % 10) {
      case 1: return `${dayOfMoth}st`;
      case 2: return `${dayOfMoth}nd`;
      case 3: return `${dayOfMoth}rd`;
      default: return `${dayOfMoth}th`;
    }
  }
}
